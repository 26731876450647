* {
  box-sizing: border-box;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;

  scrollbar-width: none;
  scrollbar-color: #aeafaf #ffffff;
  scrollbar-width: thin;
  *::-webkit-scrollbar {
    width: 10px;
  }
  *::-webkit-scrollbar-track {
    background-color: #ffffff;
    border-radius: 100px;
  }
  *::-webkit-scrollbar-thumb {
    border-radius: 100px;
    background-color: #aeafaf;
    background-clip: content-box;
  }
  *::-webkit-scrollbar-thumb:hover {
    background-color: #aeafaf;
    border-radius: 100px;
  }
  &::-webkit-scrollbar {
    display: none;
    scrollbar-width: none;
  }
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; */
}

body *::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

body *::-webkit-scrollbar-thumb {
  opacity: 0;
}

body *::-webkit-scrollbar-track {
  opacity: 0;
}

body *:hover::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 5px;
}

@import "./styles/base.css";
