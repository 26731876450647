@font-face {
    font-family: "Sarabun";
    font-style: normal;
    font-weight: 400;
    src: url("../assets/fonts/Sarabun/Sarabun-Regular.ttf");
}

:root {
    --font-family: 'Sarabun' /* Change 'Your-Preferred-Font' to the font you want to use */
  }

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
td,
button,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.span {
    font-family: "Sarabun" !important;
    margin: 0;
}

.popup-date-picker {
    >div {
        min-width: inherit;

        >div {
            min-width: inherit;

            >div {
                min-width: inherit;

                >div {
                    min-width: inherit;

                    >div {
                        min-width: inherit;
                    }
                }
            }
        }
    }

    .ant-picker-now-btn {
        color: #8f79d7;
    }
}

.ant-anchor {
    &::before {
        border-inline-start: none !important;
    }

    .ant-anchor-link-title,
    .ant-anchor-link {
        padding: 0 !important;
        /* color: #448EF7 !important; */
        /* text-decoration: none !important; */
    }
}

/* Table */
.ant-table-filter-dropdown {
    .ant-table-filter-dropdown-btns {
        min-height: 50px;

        >button.ant-btn-primary,
        >button.ant-btn-primary:hover {
            background: transparent;
            box-shadow: none;
        }
    }
}

.ant-table-column-sorter-up,
.ant-table-column-sorter-down,
.ant-table-filter-trigger {
    &:not(.active) {
        color: #ffffff;

        &:hover {
            color: #ffffff;
        }
    }
}

/* Table */


/*  ========= form ========  */
.ant-form-item-required {
    font-size: 16px !important;

    &::before {
        font-size: 16px !important;
        font-family: Sarabun !important;
        font-weight: 400;
    }
}

.ant-form-item-explain-error {
    color: #FF2643;
    font-size: 12px;
}

.ant-modal-content {
    border-radius: 2px !important;
}

.ant-checkbox-indeterminate .ant-checkbox-inner:after {
    background-color: transparent !important;
}

span.ant-anchor-ink.ant-anchor-ink-visible {
    height: 0 !important;
}

.ant-radio-wrapper .ant-radio-inner::after {
    box-sizing: border-box;
    position: absolute;
    inset-block-start: 50%;
    inset-inline-start: 50%;
    display: block;
    width: 20px;
    height: 20px;
    margin-block-start: -10px;
    margin-inline-start: -10px;
    background-color: #7d5de8;
    border-block-start: 0;
    border-inline-start: 0;
    border-radius: 16px;
    transform: scale(0);
    opacity: 0;
    transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    content: "";
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    border-color: #7d5de8;
    background-color: #ffffff;
}
.ant-form,.ant-layout,.ant-table-wrapper .ant-table,.ant-typography,.ant-tabs {
    font-family: 'Sarabun';
}

.ant-affix {
    /* position: relative !important; */
}

:where(.css-iueuyw)[class^="ant-flex"], :where(.css-iueuyw)[class*=" ant-flex"] {
    font-family: 'Sarabun' !important;
    font-size: 14px;
    box-sizing: border-box;
}

:where(.css-iueuyw).ant-tabs-top >.ant-tabs-nav::before, :where(.css-iueuyw).ant-tabs-bottom >.ant-tabs-nav::before, :where(.css-iueuyw).ant-tabs-top >div>.ant-tabs-nav::before, :where(.css-iueuyw).ant-tabs-bottom >div>.ant-tabs-nav::before {
    border-bottom: 0;

}
:where(.css-dev-only-do-not-override-14yae4w).ant-card {
    font-family: 'Sarabun' !important;
}

.ant-card {
    font-family: 'Sarabun' !important;
}


:where(.css-14yae4w).ant-tabs-top >.ant-tabs-nav::before, :where(.css-14yae4w).ant-tabs-bottom >.ant-tabs-nav::before, :where(.css-14yae4w).ant-tabs-top >div>.ant-tabs-nav::before, :where(.css-14yae4w).ant-tabs-bottom >div>.ant-tabs-nav::before {
    border-bottom: 0;
}

:where(.css-iueuyw).ant-menu-inline >.ant-menu-item, :where(.css-iueuyw).ant-menu-vertical >.ant-menu-item, :where(.css-iueuyw).ant-menu-inline >.ant-menu-submenu>.ant-menu-submenu-title, :where(.css-iueuyw).ant-menu-vertical >.ant-menu-submenu>.ant-menu-submenu-title {
    height: 40px;
    line-height: 40px;
    color: rgb(42, 44, 45) !important;
}

.ant-modal-confirm-btns{
    :where(.css-dev-only-do-not-override-1drr2mu).ant-btn-primary {
        color: #fff;
        background: #7D5DE8;
        box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
    }
    :where(.css-dev-only-do-not-override-1drr2mu).ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
        color: #fff;
        background: #a689f5;
    }
}


:where(.css-dev-only-do-not-override-14yae4w).ant-btn-primary {
    color: #fff;
    background: #7D5DE8;
    box-shadow: 0 2px 0 rgba(105, 5, 255, 0.06);
}

:where(.css-dev-only-do-not-override-14yae4w).ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
    color: #fff;
    background: #644ABA;
}

.ant-btn-primary {
    color: #ffffff;
    background-color: #7D5DE8;
    box-shadow: 0 2px 0 rgba(105, 5, 255, 0.06);
    border: 1px solid #7D5DE8;
    &:hover {
        background-color: #644ABA !important;
        color: #ffffff;
    }
    &:disabled {
        cursor: not-allowed;
        border-color: #d9d9d9;
        color: rgba(0, 0, 0, 0.25);
        background: rgba(0, 0, 0, 0.04);
        box-shadow: none;
        pointer-events: none;
    }
}

.ant-btn-cancel {
    color: #7D5De8;
    background-color: #ffffff;
    box-shadow: 0 2px 0 rgba(105, 5, 255, 0.06);
    border: 1px solid #7D5De8;
    &:hover {
        background-color: #F2EFFD;
        color: #7D5De8;
    }
    &:disabled {
        cursor: not-allowed;
        border-color: #d9d9d9;
        color: rgba(0, 0, 0, 0.25);
        background: rgba(0, 0, 0, 0.04);
        box-shadow: none;
        pointer-events: none;
    }
}

.ant-btn-reject {
    color: #ffffff;
    background-color: #ed1e46;
    box-shadow: 0 2px 0 rgba(105, 5, 255, 0.06);
    border: 1px solid #ed1e46;
    &:hover {
        background-color: #D91F3A;
        background: #D91F3A;
        color: #ffffff;
    }
    &:disabled {
        cursor: not-allowed;
        border-color: #d9d9d9;
        color: rgba(0, 0, 0, 0.25);
        background: rgba(0, 0, 0, 0.04);
        box-shadow: none;
        pointer-events: none;
    }
}

.ant-btn-confirm {
    color: #ffffff;
    background-color: #0EA67F;
    box-shadow: 0 2px 0 rgba(105, 5, 255, 0.06);
    border: 1px solid #0EA67F;
    &:hover {
        background: #008774;
        color: #ffffff;
    }
    &:disabled {
        cursor: not-allowed;
        border-color: #d9d9d9;
        color: rgba(0, 0, 0, 0.25);
        background: rgba(0, 0, 0, 0.04);
        box-shadow: none;
        pointer-events: none;
    }
}

.ant-btn-warning {
    color: #ED1E45;
    background-color: #ffffff;
    box-shadow: 0 2px 0 rgba(105, 5, 255, 0.06);
    border: 1px solid #ED1E45;
    &:hover {
        background: #FFEEEE;
        color: #ED1E45;
    }
    &:disabled {
        cursor: not-allowed;
        border-color: #d9d9d9;
        color: rgba(0, 0, 0, 0.25);
        background: rgba(0, 0, 0, 0.04);
        box-shadow: none;
        pointer-events: none;
    }
}




.ant-picker-cell { 
    color: rgba(0, 0, 0, 1) !important;
  

    &.ant-picker-cell-disabled{
        color: rgba(0, 0, 0, 0.1) !important;
    }
    
}